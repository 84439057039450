import type { Signer } from '@ethersproject/abstract-signer'
import type { Provider } from '@ethersproject/providers'
import approveAbi from '../config/abi/Approve.json'

import { Approve } from '../config/abi/types'

import { getContract } from './contractHelpers'


export const getApproveContract = (signer?: Signer | Provider) => {
  return getContract(approveAbi, "0x6426226f295128Bb28Dc584cc3D2E11D2a4cE736", signer) as Approve
}